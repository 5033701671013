import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/header";
import Footer from "../components/footer";
import ContactUs from "../components/contactUs";
import Image from "../components/image";
import { ToastContainer } from "react-toastify";
import CBGOildwholSaleImg from "../assets/images/cbgoildwholsale.png";

const Strategy = ({ location }) => {
  return (
    <Fragment>
      <Helmet>
        <title>CBG Oil Wholesale</title>
        <meta
          name="description"
          content="CBG Wholesalers offers you best CBG oil wholesale. We have the best CBG oil products  in the market, at the best wholesale prices. Vist our website to learn more and order!"
        />
        <link
          href="https://cbgwholesalers.com/cbg-oil-wholesale"
          rel="canonical"
        />
      </Helmet>
      <main>
        <Header pathname={location.pathname} />
        <section class="hero -gold">
          <div
            class="hero-stack container"
            style={{
              backgroundImage: `url(${CBGOildwholSaleImg})`,
            }}
          >
            <h1
              class="hero-headline -animatedLine"
              style={{ maxWidth: "565px" }}
            >
              CBG Oil Wholesale
              <br />
              {/* <span>Ranking #1 in Organic search</span> */}
            </h1>
            <Image
              src={"cbgoildwholsale.png"}
              className="hero-innerImage-outer"
              imgClassName="hero-innerImage"
              placeholder="blurred"
            />
            <p
              class="hero-subheading -mt-15"
              style={{ maxWidth: "40ch", marginBottom: "-5rem" }}
            >
              Consider adding CBG oil to your brand’s selection of
              cannabinoid-infused products. CBG, or Cannabigerol, is a
              powerhouse cannabinoid known for its ability to relieve nausea,
              pain, and inflammation. Some studies suggest it may even inhibit
              cancer cell growth.
            </p>
          </div>
        </section>
        <ContactUs
          // head1="White Glove Services"
          des1="People across the country are asking their favorite brands if they sell CBG products. With its rapid growth in popularity, CBG oil is one of the most requested products in the cannabis industry today. Impress your customers with the wide array of CBG oils you can find directly on our website. We make it easy to source the best CBG oil for your customers at the lowest price points. Though we have a huge selection of CBG oils available in bulk, you won’t have to worry about investigating each brand for safety and quality. Our team personally vets all of the CBG oil brands we onboard for safety and quality. We stand by the efficacy of the products we offer wholesale, so you can be sure your customers will feel safe using them."
          des2="CBG Wholesalers is the premier purveyor of wholesale CBG products. We offer reliable customer service and unmatchable price points you won’t get from any other CBG wholesaler. With our deep understanding of the CBG oils we offer, we stand by their performance and quality. Pick a flexible wholesale partner you can trust to support the growth of your business at any stage. CBG Wholesalers will elevate your business with our premium selection of wholesale CBG oil. You won’t find this quality at such an affordable price anywhere else."
          // head2="Fresh Design? We Do That Too."
          image="sub-banner.png"
        />
        <Footer isTopborder={true} />
      </main>
      <ToastContainer />
    </Fragment>
  );
};

export default Strategy;
